<template>
  <div>
    <!-- Hero Section -->
    <section class="bg-primary text-white py-16 md:py-24">
      <div class="container-custom">
        <div class="max-w-3xl">
          <h1 class="text-4xl md:text-5xl font-bold mb-4">
            Servicios de impresión profesional en Puerto Rico
          </h1>
          <p class="text-xl mb-8">
            Ofrecemos soluciones completas de impresión, bordado y serigrafía para negocios y particulares en toda la isla.
          </p>
          <div class="flex flex-wrap gap-4">
            <router-link
              to="/services"
              class="bg-white text-primary hover:bg-gray-100 px-6 py-3 rounded-md font-medium transition"
            >
              Ver servicios
            </router-link>
            <router-link
              to="/contact"
              class="bg-primary-dark hover:bg-primary-darker text-white px-6 py-3 rounded-md font-medium border border-white/20 transition"
            >
              Contáctanos
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Overview -->
    <section class="py-16 bg-gray-50">
      <div class="container-custom">
        <div class="text-center mb-12">
          <h2 class="text-3xl font-bold mb-4">Nuestros servicios de impresión</h2>
          <p class="text-gray-600 max-w-2xl mx-auto">
            Desde artículos promocionales hasta uniformes escolares, ofrecemos una amplia gama de servicios de impresión para satisfacer todas tus necesidades en Puerto Rico.
          </p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div
            v-for="service in services"
            :key="service.key"
            class="bg-white p-6 rounded-lg shadow-md"
          >
            <div class="w-12 h-12 bg-primary/10 rounded-full flex items-center justify-center mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">{{service.name}}</h3>
            <p class="text-gray-600 mb-4">
              {{service.description}}
            </p>
            <router-link to="/services" class="text-primary font-medium hover:underline">
              Ver más →
            </router-link>
          </div>
        </div>

        <div class="text-center mt-12">
          <router-link
            to="/services"
            class="inline-block bg-primary hover:bg-primary-dark text-white font-medium py-3 px-6 rounded-md transition"
          >
            Explorar todos los servicios
          </router-link>
        </div>
      </div>
    </section>

    <!-- About Section Preview -->
    <section class="py-16">
      <div class="container-custom">
        <div class="grid md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 class="text-3xl font-bold mb-4">Imprenta en Puerto Rico</h2>
            <p class="text-gray-600 mb-4">
              En Bordados y Estampados de Juncos, combinamos más de 25 años de experiencia con pasión por la excelencia. Transformamos sus ideas en realidad a través de impresión y diseño personalizado de calidad en ropa deportiva, piezas casuales, gorras y bordados. Nuestro equipo está comprometido con ofrecer precisión y creatividad en cada proyecto. ¡Contáctenos hoy y descubra la diferencia de trabajar con expertos!
            </p>
            <div>
              <router-link
                to="/about"
                class="inline-flex items-center font-medium text-primary hover:text-primary-dark"
              >
                Conoce más sobre nosotros
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CTA Section -->
    <section class="py-16 bg-primary text-white">
      <div class="container-custom text-center">
        <h2 class="text-3xl font-bold mb-4">¿Listo para comenzar tu proyecto?</h2>
        <p class="text-xl mb-8 max-w-2xl mx-auto">
          Contáctanos hoy mismo para una cotización gratuita o visita nuestra tienda en Juncos, Puerto Rico.
        </p>
        <div class="flex flex-wrap justify-center gap-4">
          <router-link
            to="/contact"
            class="bg-white text-primary hover:bg-gray-100 px-6 py-3 rounded-md font-medium transition"
          >
            Contáctanos
          </router-link>
          <a
            :href="`tel:+1${PHONE_NUMBER}`"
            class="bg-primary-dark hover:bg-primary-darker text-white px-6 py-3 rounded-md font-medium border border-white/20 transition"
          >
            Llámanos ahora
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import servicesData from '@/data/constants/services';
import { PHONE_NUMBER } from '@/data/constants/contact_us';

const services = servicesData.filter((service) => ['embroidery', 'promotional-items', 'signage'].includes(service.key));
</script> 