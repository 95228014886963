<template>
  <header class="bg-white shadow-sm">
    <div class="container-custom py-2">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <!-- Logo with Homepage Link -->
          <router-link to="/" class="inline-block">
            <img src="/assets/bej_logo.png" alt="Bordados y Estampados de Juncos" class="h-14" />
          </router-link>
        </div>
        
        <!-- Desktop Navigation -->
        <nav class="hidden md:flex space-x-8">
          <router-link 
            v-for="link in navLinks" 
            :key="link.path" 
            :to="link.path"
            class="text-gray-700 hover:text-primary font-medium transition"
            active-class="text-primary font-semibold"
          >
            {{ link.name }}
          </router-link>
        </nav>
        
        <!-- Mobile Menu Button -->
        <button 
          @click="isMenuOpen = !isMenuOpen" 
          class="md:hidden p-2 rounded-md text-gray-700 hover:bg-gray-100"
          aria-label="Alternar menú de navegación"
          :aria-expanded="isMenuOpen ? 'true' : 'false'"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path v-if="isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      
      <!-- Mobile Navigation -->
      <div v-if="isMenuOpen" class="md:hidden pt-2 pb-4">
        <div class="flex flex-col space-y-2">
          <router-link 
            v-for="link in navLinks" 
            :key="link.path" 
            :to="link.path"
            class="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
            active-class="bg-primary/10 text-primary font-medium"
            @click="isMenuOpen = false"
          >
            {{ link.name }}
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isMenuOpen = ref(false);

const navLinks = [
  { name: 'Inicio', path: '/' },
  { name: 'Servicios', path: '/services' },
  { name: 'Nosotros', path: '/about' },
  { name: 'Contacto', path: '/contact' }
];
</script> 