export interface ServiceItem {
  key: string;
  name: string;
  items: string[];
  description: string;
}

/**
 * Services data for Imprenta PR
 * Servicios de impresión en Puerto Rico
 */
const servicesData: ServiceItem[] = [
  {
    key: "promotional-items",
    name: "Artículos Promocionales",
    description: "Promociona tu negocio con artículos personalizados.",
    items: [
      "Botellas de Agua",
      "Camisetas",
      "Tarjetas de Presentación",
      "Tazas"
    ]
  },
  {
    key: "embroidery",
    name: "Bordados",
    description: "Bordados profesionales para uniformes y ropa promocional en toda la isla.",
    items: [
      "Gorras",
      "Polos",
      "Uniformes",
      "Parches bordados con adhesivo"
    ]
  },
  {
    key: "digital-printing",
    name: "Impresión Digital",
    description: "Impresión digital de calidad para proyectos profesionales en Puerto Rico.",
    items: [
      "Folletos",
      "Invitaciones",
      "Menús"
    ]
  },
  {
    key: "signage",
    name: "Rotulación",
    description: "Rotulación comercial para aumentar la visibilidad de tu negocio puertorriqueño.",
    items: [
      "Banners",
      "Letreros Comerciales",
      "Vinil Adhesivo"
    ]
  },
  {
    key: "screen-printing",
    name: "Serigrafía",
    description: "Serigrafía de calidad para todo tipo de proyectos personales y comerciales.",
    items: [
      "Camisetas",
      "Bolsas de Tela",
      "Ropa Deportivas"
    ]
  },
  {
    key: "sublimation",
    name: "Sublimación",
    description: "Sublimación perfecta para productos personalizados y artículos promocionales.",
    items: [
      "Bolígrafos personalizados",
      "Lanyards",
      "Banderas",
      "Llaveros",
      "Rompecabezas",
      "Tablillas",
      "Head bands",
      "Tazas"
    ]
  },
  {
    key: "school-uniforms",
    name: "Uniformes Escolares",
    description: "Uniformes escolares para escuelas de Puerto Rico.",
    items: [
      "Camisetas",
      "Polos",
      "Pantalones"
    ]
  },
  {
    key: "uniforms",
    name: "Uniformes y Ropa Deportiva",
    description: "Personalización de uniformes escolares y deportivos.",
    items: [
      "Uniformes escolares",
      "Camisetas",
      "Uniformes para equipos"
    ]
  },
  {
    key: "printing-materials",
    name: "Impresión",
    description: "Servicios de impresión.",
    items: [
      "Impresión DTF para camisetas",
      "Stickers en variedad de tamaños",
      "Imanes para puertas de vehículos",
      "Forros expanded para mesas"
    ]
  },
  {
    key: "wholesale-retail",
    name: "Venta de Ropa y Materiales",
    description: "Variedad de ropa y materiales al por mayor y detal.",
    items: [
      "Camisetas",
      "Jackets",
      "Hoodies",
      "Camisas",
      "Polos en microfibra",
      "Joggers",
      "Materiales para sublimación",
      "Material DTF por yarda"
    ]
  }
];

export default servicesData;