<template>
  <footer class="bg-gray-800 text-white">
    <div class="container-custom py-12">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
        <!-- Company Info -->
        <div class="col-span-1 md:col-span-2">
          <h3 class="text-xl mb-4">Bordados y Estampados de Juncos</h3>
          <p class="mb-4 text-gray-200">
            Servicios profesionales de impresión para todas tus necesidades. Trabajo de calidad con tiempos de entrega rápidos.
          </p>
          <p class="text-gray-200">
            <strong>Dirección:</strong> {{ ADDRESS }}
          </p>
        </div>
        
        <!-- Quick Links -->
        <div class="col-span-1">
          <h3 class="text-lg mb-4">Enlaces Rápidos</h3>
          <ul class="space-y-2">
            <li v-for="link in quickLinks" :key="link.path">
              <router-link :to="link.path" class="text-gray-200 hover:text-white transition">
                {{ link.name }}
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Contact -->
        <div class="col-span-1">
          <h3 class="text-lg mb-4">Contáctanos</h3>
          <ul class="space-y-1 text-gray-200">
            <li class="flex items-center">
              <i class="mdi mdi-phone-outline mr-2"></i>
              <a :href="`tel:+1${PHONE_NUMBER}`" class="hover:text-white transition">
                {{ PHONE_NUMBER_DISPLAY }}
              </a>
            </li>
            <li class="flex items-center">
              <i class="mdi mdi-email-outline mr-2"></i>
              <a :href="`mailto:${EMAIL}`" class="hover:text-white transition">
                {{ EMAIL }}
              </a>
            </li>
          </ul>
          
          <!-- Social Media Links -->
          <div class="mt-4">
            <h4 class="text-lg mb-2">Síguenos</h4>
            <div class="flex space-x-4">
              <a v-if="SOCIAL_MEDIA_LINKS.facebook" :href="SOCIAL_MEDIA_LINKS.facebook" target="_blank" rel="noopener" class="text-gray-200 hover:text-white transition">
                <span class="sr-only">Facebook</span>
                <i class="mdi mdi-facebook"></i>
              </a>
              <a v-if="SOCIAL_MEDIA_LINKS.instagram" :href="SOCIAL_MEDIA_LINKS.instagram" target="_blank" rel="noopener" class="text-gray-200 hover:text-white transition">
                <span class="sr-only">Instagram</span>
                <i class="mdi mdi-instagram"></i>
              </a>
              <a v-if="SOCIAL_MEDIA_LINKS.linkedin" :href="SOCIAL_MEDIA_LINKS.linkedin" target="_blank" rel="noopener" class="text-gray-200 hover:text-white transition">
                <span class="sr-only">LinkedIn</span>
                <i class="mdi mdi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Copyright -->
      <div class="mt-8 pt-8 border-t border-gray-700 text-center md:text-left text-gray-400">
        <p>© {{ new Date().getFullYear() }} Bordados y Estampados de Juncos. Todos los derechos reservados.</p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ADDRESS, EMAIL, PHONE_NUMBER_DISPLAY, PHONE_NUMBER, SOCIAL_MEDIA_LINKS } from '@/data/constants/contact_us';
const quickLinks = [
  { name: 'Inicio', path: '/' },
  { name: 'Servicios', path: '/services' },
  { name: 'Nosotros', path: '/about' },
  { name: 'Contacto', path: '/contact' }
];
</script>


<style scoped>
.mdi {
  font-size: 1.5rem;
}
</style>