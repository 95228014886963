export const ADDRESS: string = '66 Calle Teodomiro del Faus, Juncos PR';
export const EMAIL: string = 'estampadosj@gmail.com';
export const PHONE_NUMBER: string = '7874052982';
export const PHONE_NUMBER_DISPLAY: string = '(787)-405-2982';
export const GOOGLE_MAPS_IFRAME_URL: string = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3789.5925045928216!2d-65.9214624!3d18.2286404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c03593ea756441b%3A0x8a6c25c9a5f85399!2sBordados%20y%20Estampados%20de%20Juncos!5e0!3m2!1sen!2spr!4v1740937302853!5m2!1sen!2spr";

export const GOOGLE_MAPS_URL: string = "https://maps.app.goo.gl/72octTfHZVggfs4L7";

export const SOCIAL_MEDIA_LINKS: Record<string, string> = {
  facebook: 'https://www.facebook.com/bejuncospr',
  instagram: 'https://www.instagram.com/bejuncos',
  linkedin: 'https://www.linkedin.com/company/bordados-y-estampados-de-juncos',
};
